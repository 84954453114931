e<template>
     <div class="container-fluid">
        <Dialog :style="{ width: '45rem' }" @hide="cerrarComp" :header="'Compañías asociadas al evento: ' + eventoCompañia.nombre_evento" modal
         v-model:visible="dialogCompañias">
            <div style="display: flex; flex-direction: column; gap: 30px;">
                <div style="display: flex; gap: 5px;">
                    <input type="checkbox" @click="cambiarTodas" v-model="eventoCompañia.todas_companyia_evento" v-bind:true-value="1" v-bind:false-value="0">
                    <label style="margin: 0;">Todas</label>
                </div>
                <div v-if="eventoCompañia.todas_companyia_evento !=1 ">
                    <div style="display: flex; gap: 10px">
                        <select class="form-control" v-model="companiaSeleccionada" id="">
                            <option v-for="compania in companiasAñadir" :key="compania.id" :value="compania.id">{{ compania.nombre }}</option>
                        </select>
                        <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="anadirCompania">Añadir</button>
                    </div>
                    <div style="display: flex; gap: 10px">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>
                                        Compañías
                                    </th>
                                    <th>
    
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="compania in companiasAñadidas" :key="compania.id">
                                    <td>
                                        {{ compania.nombre }}
                                    </td>
                                    <td>
                                        <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="eliminarCompania(compania)">
                                            Eliminar
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div v-else>
                    <p>Evento configurado para todas las compañías.</p>
                </div>
            </div>
        </Dialog>
        <Dialog :style="{ width: '65rem' }" @hide="cerrarEdit" :header="'Editar evento: ' + eventoEditar.nombre_evento" modal
         v-model:visible="dialogEditar">
            <Dialog :style="{ width: '35rem' }" @hide="cerrarDialog" :header="dialogMostrar.desc" modal
                v-model:visible="dialogForm">
                <p v-if="dialogMostrar.contenido">{{ dialogMostrar.contenido }}</p>
                <p v-else>{{ dialogMostrar }}</p>
            </Dialog>
            <div  style="display: flex; flex-direction: column; gap: 15px;">
                <div v-for="form in formularioEvento" :key="form.nombre">
                    <div v-if="form.tipo == 'text'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                        <label style="margin: 0;">{{ form.desc }}</label>
                        <input type="text" v-model="form.valor" class="form-control">
                    </div>
                    <div v-if="form.tipo == 'select' && form.nombre == 'accion_evento'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                        <label style="margin: 0;">{{ form.desc }}</label>
                        <select id="" class="form-control" v-model="form.valor" @change="accionAlCambiar(form)">
                            <option v-for="valor in Object.entries(form.valores)" :key="valor[0]" :value="valor[0]">{{ valor[1] }}</option>
                        </select>
                    </div>
                    <div v-if="form.tipo == 'select' && form.nombre != 'accion_evento'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                        <label style="margin: 0;">{{ form.desc }}</label>
                        <select id="" class="form-control" v-model="form.valor">
                            <option v-for="valor in Object.entries(form.valores)" :key="valor[0]" :value="valor[0]">{{ valor[1] }}</option>
                        </select>
                    </div>
                    <div v-if="form.tipo == 'dialogo'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                        <button class="btn btn-sm btn-light" style="border:1px solid grey" @click="abrirDialog(form)">{{ form.desc }}</button>
                    </div>
                    <div v-if="form.tipo == 'number'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                        <label style="margin: 0;">{{ form.desc }}</label>
                        <input type="number" v-model="form.valor" class="form-control">
                    </div>
                    <div v-if="form.tipo == 'checkbox'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                        <label style="margin: 0;">{{ form.desc }}</label>
                        <input type="checkbox" v-model="form.checked" v-bind:true-value="1" v-bind:false-value="0">
                    </div>
                    <div v-if="form.tipo == 'radio'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                        <label style="margin: 0;">{{ form.desc }}</label>
                        <div style="display: flex; gap: 15px;">
                            <div v-for="valor in Object.entries(form.valores)" :key="valor[0]" style="display: flex; align-items: center; gap: 5px;">
                                <label style="margin: 0;">{{ valor[1] }}</label>
                                <input type="radio" v-model="form.valor">
                            </div>
                        </div>
                    </div>
                    <div v-if="form.tipo == 'select_multiple'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                        <label style="margin: 0;">{{ form.desc }}</label>
                        <MultiSelect v-model="form.valor" option-value="id" option-label="valor" :options="form.valores" :maxSelectedLabels="2"/>
                    </div>
                    <div v-if="form.tipo == 'textarea'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                        <label style="margin: 0;">{{ form.desc }}</label>
                        <textarea v-model="form.valor" class="form-control" rows="3"></textarea>
                    </div>
                    <div v-if="form.tipo == 'button'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                        <button class="btn btn-sm btn-light" style="border:1px solid grey" @click="botonEditarEvento(form)">{{ form.desc }}</button>
                    </div>
                    <!--<div v-if="form.tipo == ">

                    </div>-->
                    <div v-if="form.tipo == 'aviso'" style="text-align: center;">
                        <p style="margin: 0; font-weight: bolder;">{{ form.valor }}</p>
                    </div>
                    <div v-if="form.grupos" style="display: grid; grid-template-columns: 1fr 1fr; gap: 20px;">
                        <div v-for="grupo in form.grupos" :key="grupo" class="card">
                            <div class="card-header">
                                {{ grupo.nombre_grupo }}
                            </div>
                            <div class="card-body" style="display: flex; flex-direction: column; gap: 15px;">
                                <div v-for="campo in grupo.campos" :key="campo.nombre">
                                    <div v-if="campo.tipo == 'text'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                        <label style="margin: 0;">{{ campo.desc }}</label>
                                        <input type="text" v-model="campo.valor" class="form-control">
                                    </div>
                                    <div v-if="campo.tipo == 'select' && campo.nombre != 'accion_evento'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                        <label style="margin: 0;">{{ campo.desc }}</label>
                                        <select id="" class="form-control" v-model="campo.valor">
                                            <option v-for="valor in Object.entries(campo.valores)" :key="valor[0]" :value="valor[0]">{{ valor[1] }}</option>
                                        </select>
                                    </div>
                                    <div v-if="campo.tipo == 'number'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                        <label style="margin: 0;">{{ campo.desc }}</label>
                                        <input type="number" v-model="campo.valor" class="form-control">
                                    </div>
                                    <div v-if="campo.tipo == 'checkbox'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                        <label style="margin: 0;">{{ campo.desc }}</label>
                                        <input type="checkbox" v-model="campo.checked" v-bind:true-value="1" v-bind:false-value="0">
                                    </div>
                                    <div v-if="campo.tipo == 'button'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                        <button class="btn btn-sm btn-light" style="border:1px solid grey" @click="botonEditarEvento(campo)">{{ campo.desc }}</button>
                                    </div>
                                    <div v-if="campo.tipo == 'dialogo'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                        <button class="btn btn-sm btn-light" style="border:1px solid grey" @click="abrirDialog(campo)">{{ campo.desc }}</button>
                                    </div>
                                    <div v-if="campo.tipo == 'radio'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                        <label style="margin: 0;">{{ campo.desc }}</label>
                                        <div style="display: flex; gap: 15px;">
                                            <div v-for="valor in Object.entries(campo.valores)" :key="valor[0]" style="display: flex; align-items: center; gap: 5px;">
                                                <label style="margin: 0;">{{ valor[1] }}</label>
                                                <input type="radio" v-model="campo.valor">
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="campo.tipo == 'select_multiple'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                        <label style="margin: 0;">{{ campo.desc }}</label>
                                        <MultiSelect v-model="campo.valor" option-value="id" option-label="valor" :options="campo.valores" :maxSelectedLabels="2"/>
                                    </div>
                                    <div v-if="campo.tipo == 'textarea'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                        <label style="margin: 0;">{{ campo.desc }}</label>
                                        <textarea v-model="campo.valor" class="form-control" rows="3"></textarea>
                                    </div>
                                    <!--<div v-if="form.tipo == ">
    
                                    </div>-->
                                    <div v-if="campo.tipo == 'aviso'" style="text-align: center;">
                                        <p style="margin: 0; font-weight: bolder;">{{ campo.valor }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="display: flex;justify-content: center;">
                    <button class="brn btn-sm btn-light" style="border: 1px solid grey;" @click="guardarFormulario">Guardar</button>
                </div>
                <div class="card">
                    <div style="display: flex; justify-content: space-between;" class="card-header">
                        <h5>Acciones</h5>
                        <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="anadirAccion=true ; editaracciondialog=false; formularioAccion=[]">Añadir</button>
                    </div>
                    <div class="card-body">
                        <div class="callout" v-if="editaracciondialog" style="display: grid; gap: 15px">
                            <div style="text-align: center;">
                                <h3>Editar Función</h3>
                            </div>
                            <Dialog :style="{ width: '35rem' }" @hide="cerrarDialog" :header="dialogMostrar.desc" modal
                                v-model:visible="dialogForm">
                                <p v-if="dialogMostrar.contenido">{{ dialogMostrar.contenido }}</p>
                                <p v-else>{{ dialogMostrar }}</p>
                            </Dialog>
                            <div v-for="form in Object.entries(formularioAccion)" :key="form[0]">                        
                                <div v-if="form[1].tipo == 'text'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                    <label style="margin: 0;">{{ form[1].desc }}</label>
                                    <input type="text" v-model="form[1].valor" class="form-control">
                                </div>
                                <div v-if="form[1].tipo == 'dialogo'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                    <button class="btn btn-sm btn-light" style="border:1px solid grey" @click="abrirDialog(form[1])">{{ form[1].desc }}</button>
                                </div>
                                <div v-if="form[1].tipo == 'select' && form[1].nombre == 'funcion'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                    <label style="margin: 0;">{{ form[1].desc }}</label>
                                    <select id="" class="form-control" v-model="form[1].valor" @change="nuevoFormAccion(form[1].valor)">
                                        <option v-for="valor in Object.entries(form[1].valores)" :key="valor[0]" :value="valor[0]">{{ valor[1] }}</option>
                                    </select>
                                </div>
                                <div v-if="form[1].tipo == 'select' && form[1].nombre != 'funcion'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                    <label style="margin: 0;">{{ form[1].desc }}</label>
                                    <select id="" class="form-control" v-model="form[1].valor" @change="nuevoFormAccionSelect(form[1])">
                                        <option v-for="valor in Object.entries(form[1].valores)" :key="valor[0]" :value="valor[0]">{{ valor[1] }}</option>
                                    </select>
                                </div>
                                <div v-if="form[1].tipo == 'number'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                    <label style="margin: 0;">{{ form[1].desc }}</label>
                                    <input type="number" v-model="form[1].valor" class="form-control">
                                </div>
                                <div v-if="form[1].tipo == 'checkbox'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                    <label style="margin: 0;">{{ form[1].desc }}</label>
                                    <input type="checkbox" v-model="form[1].checked" v-bind:true-value="1" v-bind:false-value="0">
                                </div>
                                <div v-if="form[1].tipo == 'radio'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                    <label style="margin: 0;">{{ form[1].desc }}</label>
                                    <div style="display: flex; gap: 15px;">
                                        <div v-for="valor in Object.entries(form[1].valores)" :key="valor[0]" style="display: flex; align-items: center; gap: 5px;">
                                            <label style="margin: 0;">{{ valor[1] }}</label>
                                            <input type="radio" v-model="form.valor">
                                        </div>
                                    </div>
                                </div>
                                <div v-if="form[1].tipo == 'button'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                    <button class="btn btn-sm btn-light" style="border:1px solid grey" @click="botonEditarAccion(form[1])">{{ form[1].desc }}</button>
                                </div>
                                <div v-if="form[1].tipo == 'select_multiple'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                    <label style="margin: 0;">{{ form[1].desc }}</label>
                                    <MultiSelect v-model="form[1].valor" option-value="id" option-label="valor" :options="form[1].valores" :maxSelectedLabels="2"/>
                                </div>
                                <div v-if="form[1].tipo == 'textarea'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                    <label style="margin: 0;">{{ form[1].desc }}</label>
                                    <textarea v-model="form[1].valor" class="form-control" rows="3"></textarea>
                                </div>
                                <!--<div v-if="form.tipo == ">

                                </div>-->
                                <div v-if="form[1].tipo == 'aviso'" style="text-align: center;">
                                    <p style="margin: 0; font-weight: bolder;">{{ form[1].valor }}</p>
                                </div>
                                <div v-if="form[1].grupos" style="display: grid; grid-template-columns: 1fr 1fr; gap: 20px;">
                                    <div v-for="grupo in form[1].grupos" :key="grupo" class="card">
                                        <div class="card-header">
                                            {{ grupo.nombre_grupo }}
                                        </div>
                                        <div class="card-body" style="display: flex; flex-direction: column; gap: 15px;">
                                            <div v-for="campo in grupo.campos" :key="campo.nombre">
                                                <div v-if="campo.tipo == 'text'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                                    <label style="margin: 0;">{{ campo.desc }}</label>
                                                    <input type="text" v-model="campo.valor" class="form-control">
                                                </div>
                                                <div v-if="campo.tipo == 'dialogo'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                                    <button class="btn btn-sm btn-light" style="border:1px solid grey" @click="abrirDialog(campo)">{{ campo.desc }}</button>
                                                </div>
                                                <div v-if="campo.tipo == 'button'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                                    <button class="btn btn-sm btn-light" style="border:1px solid grey" @click="botonEditarAccion(campo)">{{ campo.desc }}</button>
                                                </div>
                                                <div v-if="campo.tipo == 'select' && campo.nombre != 'accion_evento'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                                    <label style="margin: 0;">{{ campo.desc }}</label>
                                                    <select id="" class="form-control" v-model="campo.valor" @change="nuevoFormAccionSelect(campo)">
                                                        <option v-for="valor in Object.entries(campo.valores)" :key="valor[0]" :value="valor[0]">{{ valor[1] }}</option>
                                                    </select>
                                                </div>
                                                <div v-if="campo.tipo == 'number'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                                    <label style="margin: 0;">{{ campo.desc }}</label>
                                                    <input type="number" v-model="campo.valor" class="form-control">
                                                </div>
                                                <div v-if="campo.tipo == 'checkbox'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                                    <label style="margin: 0;">{{ campo.desc }}</label>
                                                    <input type="checkbox" v-model="campo.checked" v-bind:true-value="1" v-bind:false-value="0">
                                                </div>
                                                <div v-if="campo.tipo == 'radio'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                                    <label style="margin: 0;">{{ campo.desc }}</label>
                                                    <div style="display: flex; gap: 15px;">
                                                        <div v-for="valor in Object.entries(campo.valores)" :key="valor[0]" style="display: flex; align-items: center; gap: 5px;">
                                                            <label style="margin: 0;">{{ valor[1] }}</label>
                                                            <input type="radio" v-model="campo.valor">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="campo.tipo == 'select_multiple'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                                    <label style="margin: 0;">{{ campo.desc }}</label>
                                                    <MultiSelect v-model="campo.valor" option-value="id" option-label="valor" :options="campo.valores" :maxSelectedLabels="2"/>
                                                </div>
                                                <div v-if="campo.tipo == 'textarea'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                                    <label style="margin: 0;">{{ campo.desc }}</label>
                                                    <textarea v-model="campo.valor" class="form-control" rows="3"></textarea>
                                                </div>
                                                <!--<div v-if="form.tipo == ">
                
                                                </div>-->
                                                <div v-if="campo.tipo == 'aviso'" style="text-align: center;">
                                                    <p style="margin: 0; font-weight: bolder;">{{ campo.valor }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="display: flex; gap: 20px;">
                                <button class="btn btn-sm btn-light" style="border: 1px solid grey" @click="guardarFormAccion">Guardar</button>
                                <button class="btn btn-sm btn-light" style="border: 1px solid grey" @click="cerrarAccionDialog">Cancelar</button>
                            </div>
                        </div>
                        <div class="callout" v-if="anadirAccion">
                            <div style="text-align: center;">
                                <h3>Añadir Función</h3>
                            </div>
                            <Dialog :style="{ width: '35rem' }" @hide="cerrarDialog" :header="dialogMostrar.desc" modal
                                v-model:visible="dialogForm">
                                <p v-if="dialogMostrar.contenido">{{ dialogMostrar.contenido }}</p>
                                <p v-else>{{ dialogMostrar }}</p>
                            </Dialog>
                            <select style="width: 50%;" v-if="!mostrarformanadiraccion" class="form-control" v-model="anadirAccionSelect" @change="cambiaAccionSelect()">
                                <option value="">---</option>
                                <option v-for="accion in funcionesdisponibles" :key="accion" :value="accion">{{ accion }}</option>
                            </select>
                            <div v-if="mostrarformanadiraccion" style="display: grid; gap: 15px">
                                <div v-for="form in formCrearAccion" :key="form.nombre">                             
                                    <div v-if="form.tipo == 'text'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                        <label style="margin: 0;">{{ form.desc }}</label>
                                        <input type="text" v-model="form.valor" class="form-control">
                                    </div>
                                    <div v-if="form.tipo == 'dialogo'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                        <button class="btn btn-sm btn-light" style="border:1px solid grey" @click="abrirDialog(form)">{{ form.desc }}</button>
                                    </div>
                                    <div v-if="form.tipo == 'select' && form.nombre == 'funcion'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                        <label style="margin: 0;">{{ form.desc }}</label>
                                        <select id="" class="form-control" v-model="form.valor" @change="nuevoFormAccionAnadir(form.valor)">
                                            <option v-for="valor in Object.entries(form.valores)" :key="valor[0]" :value="valor[0]">{{ valor[1] }}</option>
                                        </select>
                                    </div>
                                    <div v-if="form.tipo == 'select' && form.nombre != 'funcion'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                        <label style="margin: 0;">{{ form.desc }}</label>
                                        <select id="" class="form-control" v-model="form.valor" @change="nuevoFormAccionSelectAnadir(form)">
                                            <option v-for="valor in Object.entries(form.valores)" :key="valor[0]" :value="valor[0]">{{ valor[1] }}</option>
                                        </select>
                                    </div>
                                    <div v-if="form.tipo == 'number'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                        <label style="margin: 0;">{{ form.desc }}</label>
                                        <input type="number" v-model="form.valor" class="form-control">
                                    </div>
                                    <div v-if="form.tipo == 'checkbox'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                        <label style="margin: 0;">{{ form.desc }}</label>
                                        <input type="checkbox" v-model="form.checked" v-bind:true-value="1" v-bind:false-value="0">
                                    </div>
                                    <div v-if="form.tipo == 'radio'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                        <label style="margin: 0;">{{ form.desc }}</label>
                                        <div style="display: flex; gap: 15px;">
                                            <div v-for="valor in Object.entries(form.valores)" :key="valor[0]" style="display: flex; align-items: center; gap: 5px;">
                                                <label style="margin: 0;">{{ valor[1] }}</label>
                                                <input type="radio" v-model="form.valor">
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="form.tipo == 'button'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                        <button class="btn btn-sm btn-light" style="border:1px solid grey" @click="botonCrear(form)">{{ form.desc }}</button>
                                    </div>
                                    <div v-if="form.tipo == 'select_multiple'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                        <label style="margin: 0;">{{ form.desc }}</label>
                                        <MultiSelect v-model="form.valor" option-value="id" option-label="valor" :options="form.valores" :maxSelectedLabels="2"/>
                                    </div>
                                    <div v-if="form.tipo == 'textarea'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                        <label style="margin: 0;">{{ form.desc }}</label>
                                        <textarea v-model="form.valor" class="form-control" rows="3"></textarea>
                                    </div>
                                    <!--<div v-if="form.tipo == ">

                                    </div>-->
                                    <div v-if="form.tipo == 'aviso'" style="text-align: center;">
                                        <p style="margin: 0; font-weight: bolder;">{{ form.valor }}</p>
                                    </div>
                                    <div v-if="form.grupos" style="display: grid; grid-template-columns: 1fr 1fr; gap: 20px;">
                                        <div v-for="grupo in form.grupos" :key="grupo" class="card">
                                            <div class="card-header">
                                                {{ grupo.nombre_grupo }}
                                            </div>
                                            <div class="card-body" style="display: flex; flex-direction: column; gap: 15px;">
                                                <div v-for="campo in grupo.campos" :key="campo.nombre">
                                                    <div v-if="campo.tipo == 'text'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                                        <label style="margin: 0;">{{ campo.desc }}</label>
                                                        <input type="text" v-model="campo.valor" class="form-control">
                                                    </div>
                                                    <div v-if="campo.tipo == 'dialogo'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                                        <button class="btn btn-sm btn-light" style="border:1px solid grey" @click="abrirDialog(campo)">{{ campo.desc }}</button>
                                                    </div>
                                                    <div v-if="campo.tipo == 'button'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                                        <button class="btn btn-sm btn-light" style="border:1px solid grey" @click="botonCrear(campo)">{{ campo.desc }}</button>
                                                    </div>
                                                    <div v-if="campo.tipo == 'select' && campo.nombre != 'accion_evento'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                                        <label style="margin: 0;">{{ campo.desc }}</label>
                                                        <select id="" class="form-control" v-model="campo.valor" @change="nuevoFormAccionSelectAnadir(campo)">
                                                            <option v-for="valor in Object.entries(campo.valores)" :key="valor[0]" :value="valor[0]">{{ valor[1] }}</option>
                                                        </select>
                                                    </div>
                                                    <div v-if="campo.tipo == 'number'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                                        <label style="margin: 0;">{{ campo.desc }}</label>
                                                        <input type="number" v-model="campo.valor" class="form-control">
                                                    </div>
                                                    <div v-if="campo.tipo == 'checkbox'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                                        <label style="margin: 0;">{{ campo.desc }}</label>
                                                        <input type="checkbox" v-model="campo.checked" v-bind:true-value="1" v-bind:false-value="0">
                                                    </div>
                                                    <div v-if="campo.tipo == 'radio'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                                        <label style="margin: 0;">{{ campo.desc }}</label>
                                                        <div style="display: flex; gap: 15px;">
                                                            <div v-for="valor in Object.entries(campo.valores)" :key="valor[0]" style="display: flex; align-items: center; gap: 5px;">
                                                                <label style="margin: 0;">{{ valor[1] }}</label>
                                                                <input type="radio" v-model="campo.valor">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="campo.tipo == 'select_multiple'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                                        <label style="margin: 0;">{{ campo.desc }}</label>
                                                        <MultiSelect v-model="campo.valor" option-value="id" option-label="valor" :options="campo.valores" :maxSelectedLabels="2"/>
                                                    </div>
                                                    <div v-if="campo.tipo == 'textarea'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                                        <label style="margin: 0;">{{ campo.desc }}</label>
                                                        <textarea v-model="campo.valor" class="form-control" rows="3"></textarea>
                                                    </div>
                                                    <!--<div v-if="form.tipo == ">
                    
                                                    </div>-->
                                                    <div v-if="campo.tipo == 'aviso'" style="text-align: center;">
                                                        <p style="margin: 0; font-weight: bolder;">{{ campo.valor }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style="display: flex; gap: 20px;">
                                    <button class="btn btn-sm btn-light" style="border: 1px solid grey" @click="guardarFormAccionAnadir">Guardar</button>
                                    <button class="btn btn-sm btn-light" style="border: 1px solid grey" @click="cerrarAccionDialog">Cancelar</button>
                                </div>
                            </div>
                        </div>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th style="text-align: center;">
                                        Función
                                    </th>
                                    <th style="text-align: center;">
                                        Destino
                                    </th>
                                    <th style="text-align: center;">
                                        Activa
                                    </th>
                                    <th style="text-align: center;">
                                        
                                    </th>
                                    <th style="text-align: center;">
                                        
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="accion in acciones" :key="accion.id_accion">
                                    <td style="text-align: center;">
                                        {{accion.funcion}}
                                    </td>
                                    <td style="text-align: center;">
                                        {{ accion.destino_accion }}
                                    </td>
                                    <td style="text-align: center;">
                                        <input type="checkbox" v-model="accion.activa_accion" v-bind:true-value="1" v-bind:false-value="0" @click="activarAccion(accion)">
                                    </td>
                                    <td style="text-align: end;">
                                        <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="editarAccion(accion)">Editar</button>
                                    </td>
                                    <td style="text-align: end;">
                                        <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="eliminarAccion(accion)">Eliminar</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Dialog>
        <Dialog :style="{ width: '55rem' }" @hide="cerrarCrearEvento" header="Crear evento" modal
         v-model:visible="dialogCrearEvento">
             <Dialog :style="{ width: '35rem' }" @hide="cerrarDialog" :header="dialogMostrar.desc" modal
                v-model:visible="dialogForm">
                <p v-if="dialogMostrar.contenido">{{ dialogMostrar.contenido }}</p>
                <p v-else>{{ dialogMostrar }}</p>
            </Dialog>
            <div v-if="!formCrearEvento" style="display: grid; gap: 10px">
                <div style="display: grid; grid-template-columns: 1fr 1fr;">
                    <label>Nombre</label>
                    <input type="text" v-model="nombreCrearEvento" class="form-control">
                </div>
                <div style="display: grid; grid-template-columns: 1fr 1fr;">
                    <label>Acción</label>
                    <select @change="cambiaAccionNuevoEvento()" v-model="accionNuevoEvento" class="form-control">
                        <option value="">---</option>
                        <option v-for="accion in accionesDisponibles" :key="accion" :value="accion" >{{ accion }}</option>
                    </select>
                </div>
            </div>
            <div style="display: grid; gap: 10px" v-else>
                <div v-for="form in formularioCrearEvento" :key="form.nombre">                             
                    <div v-if="form.tipo == 'text'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                        <label style="margin: 0;">{{ form.desc }}</label>
                        <input type="text" v-model="form.valor" class="form-control">
                    </div>
                    <div v-if="form.tipo == 'dialogo'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                        <button class="btn btn-sm btn-light" style="border:1px solid grey" @click="abrirDialog(form)">{{ form.desc }}</button>
                    </div>
                    <div v-if="form.tipo == 'select' && form.nombre == 'accion_evento'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                        <label style="margin: 0;">{{ form.desc }}</label>
                        <select id="" class="form-control" v-model="form.valor" @change="nuevoFormCrearEvento(form.valor)">
                            <option v-for="valor in Object.entries(form.valores)" :key="valor[0]" :value="valor[0]">{{ valor[1] }}</option>
                        </select>
                    </div>
                    <div v-if="form.tipo == 'select' && form.nombre != 'accion_evento'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                        <label style="margin: 0;">{{ form.desc }}</label>
                        <select id="" class="form-control" v-model="form.valor" @change="nuevoFormCrearEventoSelect(form)">
                            <option v-for="valor in Object.entries(form.valores)" :key="valor[0]" :value="valor[0]">{{ valor[1] }}</option>
                        </select>
                    </div>
                    <div v-if="form.tipo == 'number'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                        <label style="margin: 0;">{{ form.desc }}</label>
                        <input type="number" v-model="form.valor" class="form-control">
                    </div>
                    <div v-if="form.tipo == 'checkbox'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                        <label style="margin: 0;">{{ form.desc }}</label>
                        <input type="checkbox" v-model="form.checked" v-bind:true-value="1" v-bind:false-value="0">
                    </div>
                    <div v-if="form.tipo == 'radio'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                        <label style="margin: 0;">{{ form.desc }}</label>
                        <div style="display: flex; gap: 15px;">
                            <div v-for="valor in Object.entries(form.valores)" :key="valor[0]" style="display: flex; align-items: center; gap: 5px;">
                                <label style="margin: 0;">{{ valor[1] }}</label>
                                <input type="radio" v-model="form.valor">
                            </div>
                        </div>
                    </div>
                    <div v-if="form.tipo == 'button'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                        <button class="btn btn-sm btn-light" style="border:1px solid grey" @click="botonNuevo(form)">{{ form.desc }}</button>
                    </div>
                    <div v-if="form.tipo == 'select_multiple'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                        <label style="margin: 0;">{{ form.desc }}</label>
                        <MultiSelect v-model="form.valor" option-value="id" option-label="valor" :options="form.valores" :maxSelectedLabels="2"/>
                    </div>
                    <div v-if="form.tipo == 'textarea'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                        <label style="margin: 0;">{{ form.desc }}</label>
                        <textarea v-model="form.valor" class="form-control" rows="3"></textarea>
                    </div>
                    <!--<div v-if="form.tipo == ">

                    </div>-->
                    <div v-if="form.tipo == 'aviso'" style="text-align: center;">
                        <p style="margin: 0; font-weight: bolder;">{{ form.valor }}</p>
                    </div>
                    <div v-if="form.grupos" style="display: grid; grid-template-columns: 1fr 1fr; gap: 20px;">
                        <div v-for="grupo in form.grupos" :key="grupo" class="card">
                            <div class="card-header">
                                {{ grupo.nombre_grupo }}
                            </div>
                            <div class="card-body" style="display: flex; flex-direction: column; gap: 15px;">
                                <div v-for="campo in grupo.campos" :key="campo.nombre">
                                    <div v-if="campo.tipo == 'text'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                        <label style="margin: 0;">{{ campo.desc }}</label>
                                        <input type="text" v-model="campo.valor" class="form-control">
                                    </div>
                                    <div v-if="campo.tipo == 'dialogo'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                        <button class="btn btn-sm btn-light" style="border:1px solid grey" @click="abrirDialog(campo)">{{ campo.desc }}</button>
                                    </div>
                                    <div v-if="campo.tipo == 'button'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                        <button class="btn btn-sm btn-light" style="border:1px solid grey" @click="botonNuevo(campo)">{{ campo.desc }}</button>
                                    </div>
                                    <div v-if="campo.tipo == 'select' && campo.nombre != 'accion_evento'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                        <label style="margin: 0;">{{ campo.desc }}</label>
                                        <select id="" class="form-control" v-model="campo.valor" @change="nuevoFormCrearEventoSelect(campo)">
                                            <option v-for="valor in Object.entries(campo.valores)" :key="valor[0]" :value="valor[0]">{{ valor[1] }}</option>
                                        </select>
                                    </div>
                                    <div v-if="campo.tipo == 'number'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                        <label style="margin: 0;">{{ campo.desc }}</label>
                                        <input type="number" v-model="campo.valor" class="form-control">
                                    </div>
                                    <div v-if="campo.tipo == 'checkbox'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                        <label style="margin: 0;">{{ campo.desc }}</label>
                                        <input type="checkbox" v-model="campo.checked" v-bind:true-value="1" v-bind:false-value="0">
                                    </div>
                                    <div v-if="campo.tipo == 'radio'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                        <label style="margin: 0;">{{ campo.desc }}</label>
                                        <div style="display: flex; gap: 15px;">
                                            <div v-for="valor in Object.entries(campo.valores)" :key="valor[0]" style="display: flex; align-items: center; gap: 5px;">
                                                <label style="margin: 0;">{{ valor[1] }}</label>
                                                <input type="radio" v-model="campo.valor">
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="campo.tipo == 'select_multiple'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                        <label style="margin: 0;">{{ campo.desc }}</label>
                                        <MultiSelect v-model="campo.valor" option-value="id" option-label="valor" :options="campo.valores" :maxSelectedLabels="2"/>
                                    </div>
                                    <div v-if="campo.tipo == 'textarea'" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                                        <label style="margin: 0;">{{ campo.desc }}</label>
                                        <textarea v-model="campo.valor" class="form-control" rows="3"></textarea>
                                    </div>
                                    <!--<div v-if="form.tipo == ">
    
                                    </div>-->
                                    <div v-if="campo.tipo == 'aviso'" style="text-align: center;">
                                        <p style="margin: 0; font-weight: bolder;">{{ campo.valor }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="display: flex; gap: 20px;">
                    <button class="btn btn-sm btn-light" style="border: 1px solid grey" @click="guardarFormCrearEvento">Guardar</button>
                    <button class="btn btn-sm btn-light" style="border: 1px solid grey" @click="cerrarCrearEvento">Cancelar</button>
                </div>
            </div>
        </Dialog>
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                <div class="col-sm-6">
                    <h1>Eventos</h1>
                </div>            
                </div>
            </div><!-- /.container-fluid -->
        </section>
        <section class="content">
            <div class="row" style="align-items: start">
                <div class="col-md-6 card">
                    <div class="card-header" style="display: flex; justify-content: space-between;">
                        <h5>Listado</h5>
                        <button class="btn btn-light" style="border: 1px solid grey;" @click="abirCrearEvento">Añadir evento</button>
                    </div>
                    <div class="card-body">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th style="text-align: center;">
                                        Nombre
                                    </th>
                                    <th style="text-align: center;">
                                        Activo
                                    </th>
                                    <th style="text-align: center;">
                                        
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="evento in eventos" :key="evento.id_evento">
                                    <td>
                                        {{evento.nombre_evento}}
                                    </td>
                                    <td style="text-align: center;">
                                        <input type="checkbox" v-model="evento.activo_evento" v-bind:true-value="1" v-bind:false-value="0" @click="activarEvento(evento)">
                                    </td>
                                    <td style="justify-content: space-around; display: flex;">
                                        <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="abridDialogEdit(evento)">Editar</button>
                                        <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="abridDialogComp(evento)">Compañías</button>
                                        <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="eliminarEvento(evento)">Eliminar</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-md-1">

                </div>
                <div class="col-md-4 card">
                    <Dialog :style="{ width: '35rem' }" @hide="cerrarAnadirFestivo" header="Añadir Festivo" modal
                         v-model:visible="dialogAnadirFestivo">
                         <div style="display: flex; justify-content: space-between; align-items: end">
                            <div class="form-group" style="margin: 0">
                                <label for="">Nombre</label>
                                <input type="text" class="form-control" v-model="nombreAnadirFestivo">
                            </div>
                            <div class="form-group" style="margin: 0">
                                <label for="">Día</label>
                                <input type="date" class="form-control" v-model="fechaAnadirFestivo">
                            </div>
                            <div>
                                <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="anadirFestivo">Añadir</button>
                            </div>
                         </div>
                    </Dialog>
                    <div class="card-header" style="display: flex; justify-content: space-between;">
                        <h5>Festivos</h5>
                        <button class="btn btn-light" style="border: 1px solid grey;" @click="abrirAnadirFestivo">Añadir festivo</button>
                    </div>
                    <div class="card-body">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>
                                        Nombre
                                    </th>
                                    <th>
                                        Día
                                    </th>
                                    <th>
                                        
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="festivo in festivos" :key="festivo.id">
                                    <td>
                                        {{festivo.nombre}}
                                    </td>
                                    <td>
                                        {{festivo.fecha}}
                                    </td>
                                    <td style="text-align: end;">
                                        <button class="btn btn-light btn-sm" style="border: 1px solid grey;" @click="eliminarFestivo(festivo)">Eliminar</button>
                                    </td>                                    
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-md-1"></div>
            </div> 
        </section>
    </div>
</template>
<script>
import MultiSelect from 'primevue/multiselect';
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    components: {
        MultiSelect,
    },
    data() {
        return {
            nombreAnadirFestivo:'',
            nombreCrearEvento:'',
            accionNuevoEvento:'',
            anadirAccionSelect:'',
            companiaSeleccionada:'',
            eventos:[],
            eventoCompañia:'',
            compañias:[],
            companiasAñadidas:[],
            companiasAñadir:[],
            dialogCompañias:false,
            idsComp:[],
            eventoEditar:'',
            dialogEditar:false,
            formularioEvento:'',
            acciones:[],
            formularioAccion:[],
            editaracciondialog:false,
            funcionesdisponibles:[],
            accionaeditar:'',
            dialogForm:false,
            dialogMostrar:'',
            anadirAccion:false,
            formCrearAccion:[],
            mostrarformanadiraccion:false,
            dialogCrearEvento:false,
            accionesDisponibles:[],
            formularioCrearEvento:[],
            formCrearEvento:false,
            festivos:[],
            dialogAnadirFestivo:false,
            fechaAnadirFestivo:'',
            año_actual:'',
        }
    },
    methods: {
        async eliminarFestivo(festivo){
            const api = new PwgsApi();
            try{
                await api.delete('festivos/'+festivo.id);
                setTimeout(() => {
                    this.obtenerFestivos();
                }, 500);                 
            }catch(e){
                this.$toast.add({ severity: 'error', summary: 'Error', detail: e, life: 2000 });
            }
        },
        async anadirFestivo(){
            const api = new PwgsApi();
            try{
                await api.post('festivos', {fecha:this.fechaAnadirFestivo, nombre:this.nombreAnadirFestivo});                
                setTimeout(() => {
                    this.obtenerFestivos();
                    this.cerrarAnadirFestivo();
                }, 500);                 
            }catch(e){
                this.$toast.add({ severity: 'error', summary: 'Error', detail: e, life: 2000 });
            }
        },
        abrirAnadirFestivo(){
            this.dialogAnadirFestivo = true;
        },
        cerrarAnadirFestivo(){
            this.dialogAnadirFestivo = false;
            this.diaHoy();
        },
        async guardarFormCrearEvento(){
            const api = new PwgsApi();
            var result = {};
            console.log('formaccion', this.formularioCrearEvento);
            for(var form of this.formularioCrearEvento){
                if(form.grupos){
                    console.log('form', form);
                    for(var grupo of form.grupos){
                        console.log('grupos', grupo);
                        for(var campo of grupo.campos){
                            console.log('campocamp', campo);
                            if(campo.tipo == 'checkbox'){
                                result[campo.nombre] = campo.checked; 
                            }else{
                                result[campo.nombre] = campo.valor; 
                            }
                        }
                    }
                }else{
                    console.log('notipo', form);
                    if(form.tipo == 'checkbox'){
                        result[form.nombre] = form.checked; 
                    }else{
                        result[form.nombre] = form.valor;
                    }
                    
                }
            }
            try{
                var resp = await api.post('eventos-pwgs', result);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                console.log('resp', resp);
                this.cerrarCrearEvento();
                this.obtenerEventos();
            }catch(error){
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
        },
        async botonNuevo(actual){
            const api = new PwgsApi();
            console.log('botonform',form,this.formularioCrearEvento);
            var body = {};
            for(var form of this.formularioCrearEvento){
                if(form.grupos){
                    for(var grupo of form.grupos){
                        for(var campo of grupo.campos){
                            if(actual.parametros.includes(campo.nombre)){
                                body[campo.nombre] = campo.valor;
                            }                            
                        }
                    }
                }else{
                    if(actual.parametros.includes(form.nombre)){
                        body[form.nombre] = form.valor;
                    }
                }
            }
            if(actual.method == 'GET'){
                try{
                    const resp = await api.get(actual.endpoint);
                    console.log('respuesta', resp);
                    this.abrirDialog(resp);
                }catch(error){
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }
            }else if(actual.method == 'POST'){
                try{
                    const resp = await api.post(actual.endpoint, body);
                    console.log('respuesta', resp);
                    this.abrirDialog(resp);
                }catch(error){
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }                
            }
        },
        async nuevoFormCrearEvento(funcionn){
            try{
                console.log('funcionncrear', funcionn);
                const api = new PwgsApi();
                var resp = await api.get('eventos-pwgs/0/formulario-accion/'+funcionn);
                for(var formu of resp){
                    if(formu.tipo == 'select_multiple'){
                        var lista = [];
                        for(var valor of Object.entries(formu.valores)){
                            var item = {id: valor[0], valor: valor[1]};
                            lista.push(item);
                        }
                        formu.valores = lista;
                        if(formu.valor.length == 1 && formu.valor[0] == ''){
                            console.log('entracambioform');
                            formu.valor = [];
                        }
                    }
                }
                this.formularioCrearEvento = resp;
            }catch(e){
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: e, life: 5000 });
            }
        },
        async cambiaAccionNuevoEvento(){
            const api = new PwgsApi();
            try{
                var resp = await api.get('eventos-pwgs/0/formulario-accion/'+this.accionNuevoEvento);
                console.log('formcrearevento', this.formularioCrearEvento);
                for(var formu of resp){
                    if(formu.tipo == 'select_multiple'){
                        var lista = [];
                        for(var valor of Object.entries(formu.valores)){
                            var item = {id: valor[0], valor: valor[1]};
                            lista.push(item);
                        }
                        formu.valores = lista;
                        if(formu.valor.length == 1 && formu.valor[0] == ''){
                            console.log('entracambioform');
                            formu.valor = [];
                        }
                    }
                }
                this.formularioCrearEvento = resp;
                this.formCrearEvento = true;
            }catch(error){
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
        },
        cerrarCrearEvento(){
            this.dialogCrearEvento = false;
        },
        abirCrearEvento(){
            this.dialogCrearEvento = true;
        },
        async botonEditarEvento(actual){
            const api = new PwgsApi();
            console.log('botonform',form,this.formularioEvento);
            var body = {};
            for(var form of Object.values(this.formularioEvento)){
                if(form.grupos){
                    for(var grupo of form.grupos){
                        for(var campo of grupo.campos){
                            if(actual.parametros.includes(campo.nombre)){
                                body[campo.nombre] = campo.valor;
                            }                            
                        }
                    }
                }else{
                    if(actual.parametros.includes(form.nombre)){
                        body[form.nombre] = form.valor;
                    }
                }
            }
            if(actual.method == 'GET'){
                try{
                    const resp = await api.get(actual.endpoint);
                    console.log('respuesta', resp);
                    this.abrirDialog(resp);
                }catch(error){
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }
            }else if(actual.method == 'POST'){
                try{
                    const resp = await api.post(actual.endpoint, body);
                    console.log('respuesta', resp);
                    this.abrirDialog(resp);
                }catch(error){
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }                
            }
        },
        async botonEditarAccion(actual){
            const api = new PwgsApi();
            console.log('botonform',form,this.formularioAccion);
            var body = {};
            for(var form of Object.values(this.formularioAccion)){
                if(form.grupos){
                    for(var grupo of form.grupos){
                        for(var campo of grupo.campos){
                            if(actual.parametros.includes(campo.nombre)){
                                body[campo.nombre] = campo.valor;
                            }                            
                        }
                    }
                }else{
                    if(actual.parametros.includes(form.nombre)){
                        body[form.nombre] = form.valor;
                    }
                }
            }
            if(actual.method == 'GET'){
                try{
                    const resp = await api.get(actual.endpoint);
                    console.log('respuesta', resp);
                    this.abrirDialog(resp);
                }catch(error){
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }
            }else if(actual.method == 'POST'){
                try{
                    const resp = await api.post(actual.endpoint, body);
                    console.log('respuesta', resp);
                    this.abrirDialog(resp);
                }catch(error){
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }                
            }
        },
        async botonCrear(actual){
            const api = new PwgsApi();
            console.log('botonform',form,this.formCrearAccion);
            var body = {};
            for(var form of Object.values(this.formCrearAccion)){
                if(form.grupos){
                    for(var grupo of form.grupos){
                        for(var campo of grupo.campos){
                            if(actual.parametros.includes(campo.nombre)){
                                body[campo.nombre] = campo.valor;
                            }                            
                        }
                    }
                }else{
                    if(actual.parametros.includes(form.nombre)){
                        body[form.nombre] = form.valor;
                    }
                }
            }
            if(actual.method == 'GET'){
                try{
                    const resp = await api.get(actual.endpoint);
                    console.log('respuesta', resp);
                    this.abrirDialog(resp);
                }catch(error){
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }
            }else if(actual.method == 'POST'){
                try{
                    const resp = await api.post(actual.endpoint, body);
                    console.log('respuesta', resp);
                    this.abrirDialog(resp);
                }catch(error){
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }                
            }
        },
        async cambiaAccionSelect(){
            if(this.anadirAccionSelect == ""){
                this.formCrearAccion = [];
                this.mostrarformanadiraccion = false;
            }else{
                const api = new PwgsApi();
                try{
                    this.formCrearAccion = await api.post('eventos-pwgs/'+this.eventoEditar.id_evento+'/formulario-funcion',{funcion:this.anadirAccionSelect});
                    console.log('formcrearaccion', this.formCrearAccion);
                    this.mostrarformanadiraccion = true;
                }catch(e){
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: e, life: 5000 });
                }
            }
        },
        abrirDialog(accion){
            this.dialogMostrar = accion;
            this.dialogForm = true;
        },
        cerrarDialog(){
            this.dialogMostrar = '';
            this.dialogForm = false;
        },
        async editarAccion(accion){
            console.log('editaccionssss');
            try{
                console.log('editaccion');
                const api = new PwgsApi();
                this.accionaeditar = accion;
                this.formularioAccion = await api.post('eventos-pwgs/'+this.eventoEditar.id_evento+'/formulario-funcion/'+accion.id_accion);
            }catch(error){
                console.log('erroreditaccion',error);
            }finally{
                this.formCrearAccion = [];
                this.anadirAccionSelect = '';
                this.mostrarformanadiraccion = false;
                this.editaracciondialog = true;
                this.anadirAccion = false;
            }
        },
        async eliminarAccion(accion){
            try{
                const api = new PwgsApi();
                await api.delete('eventos-pwgs/'+this.eventoEditar.id_evento+'/funciones/'+accion.id_accion);
            }catch(error){
                console.log(error);
            }finally{
                this.abridDialogEdit(this.eventoEditar);
            }
        },
        cerrarAccionDialog(){
            console.log('cerraracciondial');
            this.accionaeditar = '';
            this.editaracciondialog = false;
            this.mostrarformanadiraccion = false;
            this.anadirAccion = false;
            this.anadirAccionSelect = '';
            this.formCrearAccion = [];
        },
        async guardarFormAccion(){
            const api = new PwgsApi();
            var result = {};
            console.log('formaccion', this.formularioAccion);
            for(var form of Object.values(this.formularioAccion)){
                if(form.grupos){
                    console.log('form', form);
                    for(var grupo of form.grupos){
                        console.log('grupos', grupo);
                        for(var campo of grupo.campos){
                            console.log('campocamp', campo);
                            if(campo.tipo == 'checkbox'){
                                result[campo.nombre] = campo.checked; 
                            }else{
                                result[campo.nombre] = campo.valor; 
                            }
                        }
                    }
                }else{
                    console.log('notipo', form);
                    if(form.tipo == 'checkbox'){
                        result[form.nombre] = form.checked; 
                    }else{
                        result[form.nombre] = form.valor;
                    }
                    
                }
            }
            try{
                var resp = await api.put('/eventos-pwgs/'+this.eventoEditar.id_evento+'/funciones/'+this.accionaeditar.id_accion, result);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                console.log('resp', resp);
            }catch(error){
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
        },
        async guardarFormAccionAnadir(){
            const api = new PwgsApi();
            var result = {};
            console.log('formaccion', this.formCrearAccion);
            for(var form of Object.values(this.formCrearAccion)){
                if(form.grupos){
                    console.log('form', form);
                    for(var grupo of form.grupos){
                        console.log('grupos', grupo);
                        for(var campo of grupo.campos){
                            console.log('campocamp', campo);
                            if(campo.tipo == 'checkbox'){
                                result[campo.nombre] = campo.checked; 
                            }else{
                                result[campo.nombre] = campo.valor; 
                            }
                        }
                    }
                }else{
                    console.log('notipo', form);
                    if(form.tipo == 'checkbox'){
                        result[form.nombre] = form.checked; 
                    }else{
                        result[form.nombre] = form.valor;
                    }
                    
                }
            }
            try{
                var resp = await api.post('/eventos-pwgs/'+this.eventoEditar.id_evento+'/funciones', result);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                console.log('resp', resp);
                this.cerrarAccionDialog();
                this.abridDialogEdit(this.eventoEditar);
            }catch(error){
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
        },
        async nuevoFormAccion(funcionn){
            try{
                const api = new PwgsApi();
                this.formularioAccion = await api.post('eventos-pwgs/'+this.eventoEditar.id_evento+'/formulario-funcion/'+this.accionaeditar.id_accion,{funcion:funcionn});
            }catch(e){
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: e, life: 5000 });
            }
        },
        async nuevoFormAccionSelect(funcionn){
            try{
                console.log('nuevoFormAccionSelect',this.formularioAccion, funcionn);
                if(this.formularioAccion.campos_pendientes_seleccionar && this.formularioAccion.campos_pendientes_seleccionar.includes(funcionn.nombre)){
                    const api = new PwgsApi();
                    this.formularioAccion = await api.post('eventos-pwgs/'+this.eventoEditar.id_evento+'/formulario-funcion/'+this.accionaeditar.id_accion,{[funcionn.nombre]:funcionn.valor});
                }
            }catch(e){
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: e, life: 5000 });
            }
        },
        async nuevoFormAccionAnadir(funcionn){
            try{
                const api = new PwgsApi();
                this.formCrearAccion = await api.post('eventos-pwgs/'+this.eventoEditar.id_evento+'/formulario-funcion',{funcion:funcionn});
            }catch(e){
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: e, life: 5000 });
            }
        },
        async nuevoFormAccionSelectAnadir(funcionn){
            console.log('funcionn', funcionn, this.formCrearAccion);
            try{
                console.log('nuevoFormAccionSelectAadir',this.formularioAccion, funcionn);
                if(this.formCrearAccion.campos_pendientes_seleccionar && this.formCrearAccion.campos_pendientes_seleccionar.includes(funcionn.nombre)){
                    const api = new PwgsApi();
                    var funcionact='';
                    for(var func of Object.values(this.formCrearAccion)){
                        if(func.nombre == 'funcion'){
                            funcionact = func.valor;
                        }
                    }
                    this.formCrearAccion = await api.post('eventos-pwgs/'+this.eventoEditar.id_evento+'/formulario-funcion',{funcion:funcionact,[funcionn.nombre]:funcionn.valor});
                }
            }catch(e){
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: e, life: 5000 });
            }
        },
        async guardarFormulario(){
            const api = new PwgsApi();
            var result = {};
            for(var form of this.formularioEvento){
                if(form.grupos){
                    for(var grupo of form.grupos){
                        for(var campo of grupo.campos){
                            if(campo.tipo == 'checkbox'){
                                result[campo.nombre] = campo.checked; 
                            }else{
                                result[campo.nombre] = campo.valor; 
                            }
                        }
                    }
                }else{
                    if(form.tipo == 'checkbox'){
                        result[form.nombre] = form.checked; 
                    }else{
                        result[form.nombre] = form.valor;
                    }
                }
            }
            try{
                var resp = await api.put('/eventos-pwgs/'+this.eventoEditar.id_evento, result);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                console.log('resp', resp);
            }catch(error){
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
        },
        async accionAlCambiar(form){
            const api = new PwgsApi();
            console.log('form',form);
            try{
                var accion =  form.valor;
                if(accion == '----'){
                    console.log('entra');
                    this.formularioEvento = this.formularioEvento.filter(form => form.nombre=='nombre_evento' || form.nombre=='accion_evento');
                }else{
                    const resp = await api.get('eventos-pwgs/'+this.eventoEditar.id_evento+'/formulario-accion/'+accion);
                    console.log("respAcciones", resp);
                    for(var formu of resp){
                        if(formu.tipo == 'select_multiple'){
                            var lista = [];
                            for(var valor of Object.entries(formu.valores)){
                                var item = {id: valor[0], valor: valor[1]};
                                lista.push(item);
                            }
                            formu.valores = lista;
                            if(formu.valor.length == 1 && formu.valor[0] == ''){
                                console.log('entracambioform');
                                formu.valor = [];
                            }
                        }
                    }
                    this.formularioEvento = resp;
                }
            }catch(e){
                console.log('Error al obtener compañias', e);
            }
        },
        async eliminarCompania(compania){
            const api = new PwgsApi();
            await api.delete('eventos-pwgs/'+this.eventoCompañia.id_evento+'/companias/'+compania.id);
            this.idsComp = this.idsComp.filter(id => compania.id != id);
            this.companiasAñadidas = this.compañias.filter(compania => this.idsComp.includes(compania.id));
            this.companiasAñadir = this.compañias.filter(compania =>!this.idsComp.includes(compania.id));
            for(var companiaas of this.companiasAñadir){
                this.companiaSeleccionada = companiaas.id;
                break;
            }
        },
        async anadirCompania(){
            const api = new PwgsApi();
            await api.post('eventos-pwgs/'+this.eventoCompañia.id_evento+'/companias',{id_compania:this.companiaSeleccionada});
            this.idsComp.push(this.companiaSeleccionada);
            this.companiasAñadidas = this.compañias.filter(compania => this.idsComp.includes(compania.id));
            this.companiasAñadir = this.compañias.filter(compania =>!this.idsComp.includes(compania.id));
            for(var companiaas of this.companiasAñadir){
                this.companiaSeleccionada = companiaas.id;
                break;
            }
        },
        async cambiarTodas(){
            const api = new PwgsApi();
            await api.put('eventos-pwgs/'+this.eventoCompañia.id_evento+'/companias',{todas:this.eventoCompañia.todas_companyia_evento == 1 ? 0 : 1});
        },
        cerrarComp(){            
            this.dialogCompañias = false;
        },
        async abridDialogComp(evento){
            const api = new PwgsApi();
            try{
                const resp = await api.get('eventos-pwgs/'+evento.id_evento+'/companias');
                this.companiasAñadidas = [];
                this.companiasAñadir = [];
                this.idsComp = [];
                this.eventoCompañia = evento;
                console.log('eventoDialogo', this.eventoCompañia);
                var companiasEvento = resp.datos;
                if(companiasEvento.length == 0){
                    this.companiasAñadir = this.compañias;
                    for(var companiaa of this.companiasAñadir){
                        this.companiaSeleccionada = companiaa.id;
                        break;
                    }
                }else{                    
                    for(var compania of companiasEvento){
                        this.idsComp.push(compania.companyias_id_companyia);
                    }
                    this.companiasAñadidas = this.compañias.filter(compania => this.idsComp.includes(compania.id));
                    this.companiasAñadir = this.compañias.filter(compania =>!this.idsComp.includes(compania.id));
                    for(var companiaas of this.companiasAñadir){
                        this.companiaSeleccionada = companiaas.id;
                        break;
                    }
                }
            }catch(e){
                console.log('Error al obtener compañias', e);
            }finally{                
                this.dialogCompañias = true;
            }
        },
        cerrarEdit(){
            this.dialogEditar = false;
            this.formularioEvento = [];
            this.acciones = [];
            this.funcionesdisponibles = [];
            this.eventoEditar = '';
            this.cerrarAccionDialog();
        },
        async abridDialogEdit(evento){
            const api = new PwgsApi();
            try{
                this.formularioEvento = await api.get('eventos-pwgs/'+evento.id_evento+'/formulario-accion');
                this.acciones = await api.get('eventos-pwgs/'+evento.id_evento+'/funciones');
                this.funcionesdisponibles = await api.get('eventos-pwgs/'+evento.id_evento+'/funciones-disponibles');
                this.eventoEditar = evento;
                for(var form of this.formularioEvento){
                    if(form.tipo == 'select_multiple'){
                        var lista = [];
                        for(var valor of Object.entries(form.valores)){
                            var item = {id: valor[0], valor: valor[1]};
                            lista.push(item);
                        }
                        form.valores = lista;
                        if(form.valor.length == 1 && form.valor[0] == ''){
                            console.log('entracambioform');
                            form.valor = [];
                        }
                    }
                }
            }catch(e){
                console.log('Error al obtener compañias', e);
            }finally{                
                this.dialogEditar = true;
                console.log('formularioEvento',this.formularioEvento);
            }
        },
        async eliminarEvento(evento){
            const api = new PwgsApi();
            await api.delete('eventos-pwgs/'+evento.id_evento);
            this.obtenerEventos();
        },
        async activarEvento(evento){
            const api = new PwgsApi();
            console.log('activar evento', evento);
            if(evento.activo_evento == 1){
                await api.put('eventos-pwgs/'+evento.id_evento+'/estado', {estado:0});
            }else{
                await api.put('eventos-pwgs/'+evento.id_evento+'/estado', {estado:1});
            }
        },
        async activarAccion(accion){
            const api = new PwgsApi();
            console.log('activar evento', accion);
            if(accion.activa_accion == 1){
                await api.put('eventos-pwgs/'+this.eventoEditar.id_evento+'/estado-funcion/'+accion.id_accion, {estado:0});
            }else{
                await api.put('eventos-pwgs/'+this.eventoEditar.id_evento+'/estado-funcion/'+accion.id_accion, {estado:1});
            }
        },
        async obtenerEventos(){
            const api = new PwgsApi();
            console.log('eventos');
            try{
                const resp = await api.get('eventos-pwgs');
                this.eventos = resp.datos;
                console.log('eventos',this.eventos);
            }catch(e){
                console.log('Error al obtener eventos', e);                
            }
        },
        async obtenerCompanias(){
            const api = new PwgsApi();
            const resp = await api.get('companias/simple');
            this.compañias = resp.datos;
        },
        async obtenerAccionesDisponibles(){
            const api = new PwgsApi();
            this.accionesDisponibles = await api.get('eventos-pwgs/0/acciones-disponibles');            
        },
        async obtenerFestivos(){
            const api = new PwgsApi();
            console.log('año', this.año_actual);
            var resp = await api.get('festivos/'+this.año_actual);
            this.festivos = resp.datos;
        },
        diaHoy(){
            this.año_actual = new Date().getFullYear();
            const fecha = Date.now();
            const hoy = new Date(fecha);
            this.fechaAnadirFestivo = this.fechaing(hoy.toLocaleDateString());
            console.log('diaHoy', this.fechaAnadirFestivo);
        },
        fechaing(fecha) {
          const [dia, mes, año] = fecha.split("/");
          return `${año}-${mes.padStart(2, '0')}-${dia.padStart(2, '0')}`;
      },
      fechaesp(fecha) {
          const [año, mes, dia] = fecha.split("-");
          return `${dia}/${mes}/${año}`;
      },
    },
    mounted() {
        this.obtenerEventos();
        this.obtenerCompanias();
        this.obtenerAccionesDisponibles();
        this.diaHoy();
        this.obtenerFestivos();
    }
}
</script>
<style scoped>
.card-header::after {
    display: none;
}
</style>